import React from 'react';

const Loader = () => {
    

    return (
        <div className="loader" role="alert" aria-live="assertive">

        </div>
    );
};

export default Loader;